import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Layout from '../components/layout';
import Lines from '../components/elements/lines/lines';
import background from '../assets/images/background.jpg';

class BedanktPage extends React.Component {
	render() {
		const siteTitle = get(this, 'props.data.site.siteMetadata.title')
		const [footer] = get(this, 'props.data.allContentfulFooterMenu.edges')
		const [menu] = get(
			this,
			'props.data.allContentfulMainMenu.edges'
		)

		return (
			<div className='bedankt-page'>
				<Layout
					title={'Bedankt voor je mail' + ' - ' + siteTitle}
					menuItems={menu.node.menuItems}
					brandLogo={menu.node.brandLogo.file.url}
					location={this.props.location}
					footerItems={footer.node.footerItems}
					linkedIn={footer.node.linkedIn}
					facebook={footer.node.facebook}
					youtube={footer.node.youtube}
					instagram={footer.node.instagram}
				>
					<div className="bedankt-wrapper">
						<div className="wrapper">
							<h1>Bedankt voor het invullen van het contact formulier!</h1>
							<div className="content">
								<p>Bedankt voor het invullen van het contact formulier, wij nemen zo snel mogelijk contact met je op. En wie weet zetten wij straks ook zo'n lekker kopje koffie voor jou!</p>
								<ul>
									<li>Terug naar vorige pagina</li>
									<li>Naar de home pagina</li>
								</ul>
							</div>
						</div>
						<div className="background-wrapper">
							<Lines />
							<img src={background} />
						</div>
					</div>
				</Layout>
			</div>
		)
	}
}

export default BedanktPage
export const pageQuery = graphql`
	query BedanktPageQuery {
		site {
			siteMetadata {
				title
			}
		}
		allContentfulFooterMenu(filter: {title: {eq: "Footer"}}) {
			edges {
				node {
  					...Footer
        		}
      		}
		}
		allContentfulMainMenu(filter: {title: {eq: "Hoofdmenu"}}) {
			edges {
				node {
  					...MainMenu
        		}
      		}
		}
	}
`
